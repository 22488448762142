/* AdminPanel.css */
/* Admin.css */

.admin-panel {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.login-form {
  max-width: 400px; /* Set max-width to ensure form doesn't expand too much on larger screens */
  margin: 0 auto; /* Center the form horizontally */
  padding: 20px; /* Add some padding for better spacing */

  /* Additional styling */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form input[type="text"],
.login-form input[type="password"],
.login-form button {
  width: 100%; /* Set inputs and button to full width */
  margin-bottom: 15px; /* Add margin between elements */
}

/* Adjustments for smaller screen sizes */
@media only screen and (max-width: 576px) {
  .login-form {
      max-width: 300px; /* Adjust max-width for smaller screens */
  }
}

/* ------------------------------------ */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tab.active {
  background-color: #007bff;
  color: #fff;
}

.data-table {
  overflow-x: auto;
}

.data-table table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th, .data-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #f0f0f0;
}

.data-table th:first-child, .data-table td:first-child {
  min-width: 100px;
}

.data-table img {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
}

.admin-panel {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tab.active {
    background-color: #007bff;
    color: #fff;
  }
  
  .data-table {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
  
  th {
    background-color: #f0f0f0;
  }
  