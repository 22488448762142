/* YourComponent.css */
/* YourComponent.css */

.crypto-form {
    border: 1px solid #80808029;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    padding: 20px;
    margin-top: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .buy-button, .sell-button {
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buy-button {
    background-color: green;
    border: 1px solid green;
    color: white;
  }
  
  .sell-button {
    background-color: red;
    border: 1px solid red;
    color: white;
  }
  
  .buy-button.clicked, .sell-button.clicked {
    opacity: 0.7;
  }
  
  .form-heading {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .powered-by {
    margin-top: 10px;
  }
  
  .simplex-logo {
    max-width: 7rem;
    height: 40px;
  }
  
  .login-form {
    margin-top: 20px;
  }
  
  .learn-more, .support {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .support a {
    color: black;
    text-decoration: none;
  }
  
.crypto-ui {
    text-align: center;
    padding: 20px;
  }
  
  .partner-logo {
    height: 40px;
  }
  
  .heading {
    font-weight: bold;
    margin-top: 20px;
  }
  
  .crypto-form {
    border: 1px solid #80808029;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    padding: 20px;
    margin-top: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .buy-button, .sell-button {
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buy-button {
    background-color: green;
    border: 1px solid green;
    color: white;
  }
  
  .sell-button {
    background-color: red;
    border: 1px solid red;
    color: white;
  }
  
  .form-heading {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .powered-by {
    margin-top: 10px;
  }
  
  .simplex-logo {
    max-width: 7rem;
    height: 40px;
  }
  
  .login-form {
    margin-top: 20px;
  }
  
  .learn-more, .support {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .support a {
    color: black;
    text-decoration: none;
  }
  