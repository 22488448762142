.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  
  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .input-row input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-row button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .input-row button:hover {
    background-color: #0056b3;
  }
  
  .input-row div {
    margin-left: 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .input-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .input-row input {
    width: 200px;
    margin: 0 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-row button {
    margin: 0 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .input-row div {
    margin: 10px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .input-row input {
      width: calc(100% - 20px);
      margin: 0 10px;
    }
  
    .input-row button {
      width: calc(100% - 20px);
      margin: 0 10px;
    }
  }
  