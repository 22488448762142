.payment-message {
    background-color: #f0f0f0;
    border: 1px solid #80808029;
    border-radius: 5px;
    padding: 20px;
    text-align: center; /* Center align text */
    margin: 100px auto; /* Center horizontally */
    max-width: 90%; /* Set max-width to ensure it doesn't overflow on smaller screens */
}

/* Adjustments for smaller screen sizes */
@media only screen and (max-width: 576px) {
    .payment-message {
        margin: 50px auto; /* Adjust margin for smaller screens */
        padding: 10px; /* Adjust padding for smaller screens */
    }
}
