/*! CSS Used from: https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/css/ramp-new-website.38d6fd81b.min.css */
nav{display:block;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
*{box-sizing:border-box;}
img{vertical-align:middle;max-width:100%;display:inline-block;}
.w-inline-block{max-width:100%;display:inline-block;}
.w-button{color:#fff;line-height:inherit;cursor:pointer;background-color:#3898ec;border:0;border-radius:0;padding:9px 15px;text-decoration:none;display:inline-block;}
@media screen and (max-width:767px){
.w-nav-brand{padding-left:10px;}
}
.w-nav{z-index:1000;background:#ddd;position:relative;}
.w-nav:before,.w-nav:after{content:" ";grid-area:1/1/2/2;display:table;}
.w-nav:after{clear:both;}
.w-nav-brand{float:left;color:#333;text-decoration:none;position:relative;}
.w-nav-link{vertical-align:top;color:#222;text-align:left;margin-left:auto;margin-right:auto;padding:20px;text-decoration:none;display:inline-block;position:relative;}
.w-nav-menu{float:right;position:relative;}
.w-nav-overlay{width:100%;display:none;position:absolute;top:100%;left:0;right:0;overflow:hidden;}
.w-nav[data-animation=over-left] .w-nav-overlay{width:auto;}
.w-nav[data-animation=over-left] .w-nav-overlay{z-index:1;top:0;right:auto;}
.w-nav-button{float:right;cursor:pointer;-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-ms-user-select:none;user-select:none;padding:18px;font-size:24px;display:none;position:relative;}
.w-nav-button:focus{outline:0;}
@media screen and (max-width:991px){
.w-nav[data-collapse=medium] .w-nav-menu{display:none;}
.w-nav[data-collapse=medium] .w-nav-button{display:block;}
}
@media screen and (max-width:767px){
.w-nav-brand{padding-left:10px;}
}
a{color:var(--black);text-decoration:none;}
img{max-width:100%;display:inline-block;}
.button{background-color:var(--brand-green);color:var(--white);letter-spacing:.02em;text-shadow:0 2px 2px rgba(0,0,0,.2);border:1px solid transparent;border-radius:8px;padding:12px 20px;font-family:Spacegrotesk,sans-serif;font-size:16px;font-weight:700;line-height:125%;transition:all .2s;position:static;top:0%;bottom:0%;left:auto;right:0%;box-shadow:0 8px 8px rgba(0,0,0,.03);}
.button:hover{background-color:#22774e;}
.button.mobile{display:none;}
.button.desktop{display:block;}
.bg-color-black{background-color:var(--black);}
.nav-link{color:#14161a;border-bottom:2px solid transparent;margin-left:12px;margin-right:12px;padding:12px 0 4px;font-family:Spacegrotesk,sans-serif;line-height:19px;transition:all .2s;}
.nav-link:hover{border-bottom-color:var(--brand-green);}
.nav-link.white{color:#fff;}
.nav-link.white:hover{border-bottom-color:var(--white);}
.container-large-navbar{justify-content:space-between;align-items:center;max-width:1280px;margin-left:auto;margin-right:auto;padding:16px 40px;display:flex;}
.navbar{background-color:transparent;}
.navbar.bg-color-black{z-index:49;background-color:#000;}
.nav-menu{align-items:center;}
.nav-button-wrapper{margin-left:12px;}
.nav-mobile-wrapper{display:none;}
.show-mobile{display:none;}
.nav-menu-links-wrapper{align-items:center;display:flex;}
.nav-logo-flex{display:none;}
.nav-link-wrapper{align-self:stretch;}
@media screen and (max-width:991px){
.button.mobile{display:none;}
.button.desktop{display:block;}
.nav-link{color:#fff;margin-left:0;font-size:32px;font-weight:700;line-height:120%;}
.nav-link:hover{border-bottom-color:transparent;}
.nav-link.white:hover{border-bottom-color:var(--black);}
.navbar.bg-color-black{z-index:49;}
.nav-menu{background-color:transparent;width:100vw;height:100vh;}
.nav-button-wrapper{z-index:2;width:100%;margin-top:100px;margin-left:0;}
.nav-mobile-wrapper{grid-column-gap:4px;align-items:center;display:flex;}
.nav-menu-links-wrapper{z-index:2;background-image:url(https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645e75f3a2b27abae0da98a5_BG.webp);background-position:0 0;background-repeat:no-repeat;background-size:cover;flex-direction:column;justify-content:flex-start;align-items:flex-start;width:100vw;height:100vh;padding:16px 40px 40px;position:absolute;}
.nav-mobile-image{width:119px;display:flex;}
.nav-logo-flex{justify-content:space-between;align-items:center;width:100%;margin-bottom:8px;display:flex;}
.nav-white-hamburger{width:22px;}
.desktop{display:none;}
}
@media screen and (max-width:767px){
.button.nav-mobile{min-height:40px;padding-left:16px;padding-right:16px;font-size:14px;line-height:110%;display:block;}
.button.nav-mobile.hide-mobile{display:none;}
.button.mobile{display:block;}
.button.desktop{display:none;}
.container-large-navbar{padding:16px;}
.brand{padding-left:0;}
.hide-mobile{display:none;}
.show-mobile{display:block;}
.nav-menu-links-wrapper{padding-left:20px;padding-right:20px;}
}
@media screen and (max-width:479px){
.button.nav-mobile{text-align:center;border-radius:4px;}
.container-large-navbar{grid-column-gap:20px;}
.nav-mobile-wrapper{align-items:center;}
.brand{max-width:90px;}
.show-mobile{display:block;}
.nav-mobile-image{width:90px;}
.menu-button{padding-right:0;}
.nav-white-hamburger{width:26px;}
}
/*! CSS Used fontfaces */
@font-face{font-family:'Spacegrotesk';src:url('https://assets.website-files.com/63fe1b7ead2cd2d5e0af02e7/6418c4483965273f3a11d836_SpaceGrotesk-Bold.woff2') format('woff2');font-weight:700;font-style:normal;font-display:swap;}
@font-face{font-family:'Spacegrotesk';src:url('https://assets.website-files.com/63fe1b7ead2cd2d5e0af02e7/6418c4493bb6140eda342454_SpaceGrotesk-Regular.woff2') format('woff2');font-weight:400;font-style:normal;font-display:swap;}
@font-face{font-family:'Spacegrotesk';src:url('https://assets.website-files.com/63fe1b7ead2cd2d5e0af02e7/6418c4495da74d89870d8684_SpaceGrotesk-SemiBold.woff2') format('woff2');font-weight:600;font-style:normal;font-display:swap;}

.newone{
    margin: 0px;
}
.custom-button {
    background-color: #ff0000; /* Set the background color to red */
    color: #ffffff; /* Set the text color to white */
    padding: 8px 16px; /* Adjust the padding for smaller size */
    font-size: 14px; /* Adjust the font size for smaller size */
    border-radius: 4px; /* Add border radius for rounded corners */
}

.custom-button:hover {
    background-color: #ff3333; /* Change background color on hover */
}

@media only screen and (max-width: 768px) {
    .nav-menu-links-wrapper {
        display: flex;
        flex-direction: column;
    }

    .nav-link-wrapper, .nav-button-wrapper {
        margin-bottom: 10px;
    }
}
.custom-button {
    background-color: #ff6347; /* Set the background color to coral */
    color: #ffffff; /* Set the text color to white */
    padding: 8px 16px; /* Adjust the padding for smaller size */
    font-size: 14px; /* Adjust the font size for smaller size */
    border-radius: 4px; /* Add border radius for rounded corners */
}

.custom-button:hover {
    background-color: #ff7f50; /* Change background color on hover */
}

.nav-link {
    font-size: 14px; /* Adjust the font size of the links */
    margin-right: 10px; /* Add margin between links */
}
